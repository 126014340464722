/**
 * @author Ahmed Serag
 * @date 2020-07-21
 * @description declaration of available Error messages in the app.
 * @filename errors.ts
 */

/**
 * set of available errors in the App.
 */
export const ERRORS = {
  validation: "القيم المستخدمة غير صحيحة.",
  unexpected: "خطأ غير مقصود، برجاء الاتصال بفريق الدعم.",
  updatedProducts: { empty: "لم يتم تغيير اي منتجات في القائمة" },
  credentials: "الاسم او الرقم السري المستخدم غير صحيح",
  internetDown: "الرجاء التأكد من الانترنت"
};
