/**
 * @author Ahmed Serag
 * @date 2020-07-06
 * @description Implementation of Menu context that holds active branch's menu.
 * @filename menu-context.ts
 */
import * as React from "react";
import {
  Product,
  ProductExtra,
  ProductVariantOption
} from "interfaces/product";

/**
 * Menu context that holds active branch's menu.
 */
export const MENU_CONTEXT: React.Context<{
  /**
   * list of prepped product in the menu.
   */
  preppedProducts: Product[];
  /**
   * list of ready products in the menu.
   */
  readyProducts: Product[];
  /**
   * set of product extras in the menu.
   */
  extras: ProductExtra[];
  /**
   * set of product variants options in the menu.
   */
  variants: ProductVariantOption[];
  /**
   * id of the branch that menu belongs to.
   */
  branchId: string;

  /**
   * a boolean which is true if the menu is loading.
   */
  isLoadingMenu: boolean;
  /**
   * update current menu with new branch and it's new menu.
   *
   * @param branchId id of the new branch.
   * @param menu new menu to be added to the app
   */
  updateMenu: (
    branchId: string,
    menu: {
      preppedProducts: Product[];
      readyProducts: Product[];
      extras: ProductExtra[];
      variants: ProductVariantOption[];
    }
  ) => void;
}> = React.createContext({
  branchId: null,
  updateMenu: null,
  extras: null,
  preppedProducts: null,
  readyProducts: null,
  variants: null,
  isLoadingMenu: null
});
