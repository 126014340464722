/**
 * @author Ahmed Serag
 * @date 2020-07-28
 * @description Implementation of Order related utilities.
 * @filename order.ts
 */
import * as Sentry from "@sentry/react";
import { Order as OrderAPI } from "api/order";
import { Order as OrderInterface, PaymentMethod } from "interfaces/order";
import {
  getPayloadData as _getPayloadData,
  handleError as _handleError
} from "./common";

/**
 * group of order helpers functionalities.
 */
export class Order {
  /**
   * list all the orders in the system.
   */
  public static lisOrders(): Promise<OrderInterface[]> {
    return OrderAPI.listOrders()
      .then(result => {
        return _getPayloadData(result);
      })
      .catch(error => {
        return Promise.reject(_handleError(error));
      });
  }

  /**
   * get a specific order details from the API
   */
  public static getOrderDetails(orderId: string): Promise<OrderInterface> {
    return OrderAPI.getOrderDetails(orderId)
      .then(result => {
        return _getPayloadData(result);
      })
      .catch(error => {
        const sentryEvent: Sentry.Event = {
          message: "load order details",
          environment: `${process.env.sentry_environment}`,
          contexts: {
            order: { id: orderId },
            error: { error },
            user: { token: localStorage.getItem(process.env.ACCESS_TOKEN_KEY) }
          }
        };
        Sentry.captureEvent(sentryEvent);
        return Promise.reject(_handleError(error));
      });
  }

  /**
   * Confirm an order to start preparing.
   */
  public static confirmOrder(orderId: string): Promise<OrderInterface> {
    return OrderAPI.confirmOrder(orderId)
      .then(result => {
        return _getPayloadData(result);
      })
      .catch(error => {
        const sentryEvent: Sentry.Event = {
          message: "Confirm Order",
          environment: `${process.env.sentry_environment}`,
          contexts: {
            order: { id: orderId },
            error: { error },
            user: { token: localStorage.getItem(process.env.ACCESS_TOKEN_KEY) }
          }
        };
        Sentry.captureEvent(sentryEvent);
        return Promise.reject(_handleError(error));
      });
  }

  /**
   * Cancel an order that was already made.
   */
  public static cancelOrder(
    orderId: string,
    paymentMethod: PaymentMethod
  ): Promise<OrderInterface> {
    return OrderAPI.cancelOrder(orderId, paymentMethod)
      .then(result => {
        return _getPayloadData(result);
      })
      .catch(error => {
        const sentryEvent: Sentry.Event = {
          message: "Cancel Order",
          environment: `${process.env.sentry_environment}`,
          contexts: {
            order: { id: orderId },
            error: { error },
            user: { token: localStorage.getItem(process.env.ACCESS_TOKEN_KEY) }
          }
        };
        Sentry.captureEvent(sentryEvent);
        return Promise.reject(_handleError(error));
      });
  }

  /**
   * make order ready to pick-up
   */
  public static readyOrder(orderId: string): Promise<OrderInterface> {
    return OrderAPI.readyOrder(orderId)
      .then(result => {
        return _getPayloadData(result);
      })
      .catch(error => {
        const sentryEvent: Sentry.Event = {
          message: "Mark order as ready",
          environment: `${process.env.sentry_environment}`,
          contexts: {
            order: { id: orderId },
            error: { error },
            user: { token: localStorage.getItem(process.env.ACCESS_TOKEN_KEY) }
          }
        };
        Sentry.captureEvent(sentryEvent);
        return Promise.reject(_handleError(error));
      });
  }

  /**
   * flag order as picked up
   */
  public static pickOrder(orderId: string): Promise<OrderInterface> {
    return OrderAPI.pickOrder(orderId)
      .then(result => {
        return _getPayloadData(result);
      })
      .catch(error => {
        const sentryEvent: Sentry.Event = {
          message: "pick Order",
          environment: `${process.env.sentry_environment}`,
          contexts: {
            order: { id: orderId },
            error: { error },
            user: { token: localStorage.getItem(process.env.ACCESS_TOKEN_KEY) }
          }
        };
        Sentry.captureEvent(sentryEvent);
        return Promise.reject(_handleError(error));
      });
  }
}
