/**
 * @author Raghda Wessam
 * @date 2019-07-12
 * @description Menu (products) of current branch layout.
 * @filename branch-menu.tsx
 */
import React from "react";
import { RouteComponentProps } from "react-router-dom";
import { Product } from "interfaces/product";
import { MENU_CONTEXT } from "contexts/menu-context";
import { SVGs } from "consts/svgs";
import { exist } from "utilities/common";
import { Branch as BranchUtilities } from "utilities/branch";
import ToggleOn from "static/images/icons/toggle-on.svg";
import ToggleOff from "static/images/icons/toggle-off.svg";
import { USER_CONTEXT } from "contexts/user-context";
import { Branch, BranchAvailability } from "interfaces/branch";
import Toastr from "toastr";
import { MESSAGES } from "consts/messages";
import { BranchProducts } from "./branch-products";

interface BranchMenuState {
  preppedProducts: [];
  freshProducts: [];
  extraProducts: [];
  activeProductsType: ProductsType;
}

interface ProductAvailability {
  id: string;
  name: string;
  available: boolean;
  price: number;
}

/**
 * type used in listing all products with their availability status.
 * *Think of a better name!*
 */
type ProductsAvailability = Record<string, ProductAvailability[]>;

/**
 * Type of the product.
 */
export enum ProductsType {
  preppedProducts = 1,
  freshProducts = 2,
  extraProducts = 3
}

/**
 * React component to render available products based on active tab
 *
 * @export
 * @class BranchMenu
 * @extends {React.Component<RouteComponentProps, BranchMenuState>}
 */
export class BranchMenu extends React.Component<
  RouteComponentProps,
  BranchMenuState
> {
  declare context: React.ContextType<typeof MENU_CONTEXT>;

  constructor(props: RouteComponentProps) {
    super(props);
    this.state = {
      preppedProducts: [],
      freshProducts: [],
      extraProducts: [],
      activeProductsType: ProductsType.preppedProducts
    };
    this.onChangeActiveProductsType = this.onChangeActiveProductsType.bind(
      this
    );
    this.updateBranchAvailability = this.updateBranchAvailability.bind(this);
  }

  /**
   * Switch between active products tabs
   *
   * @param {ProductsType} type
   * @memberof BranchMenu
   */
  onChangeActiveProductsType(type: ProductsType) {
    if (this.state.activeProductsType !== type) {
      this.setState({ activeProductsType: type });
    }
  }

  populateProducts(products: Product[]): ProductsAvailability {
    const populatedProducts: ProductsAvailability = {};

    for (const product of products) {
      if (exist(populatedProducts[product.category?.name])) {
        populatedProducts[product.category?.name].push({
          id: product.id,
          available: product.available,
          name: product.name,
          price: product.price
        });
      } else {
        populatedProducts[product.category?.name] = [
          {
            id: product.id,
            available: product.available,
            name: product.name,
            price: product.price
          }
        ];
      }
    }

    return populatedProducts;
  }

  updateBranchAvailability(
    newState: BranchAvailability,
    branch: Branch,
    updateBranch: (branch: Branch) => Promise<unknown>
  ): void {
    BranchUtilities.setBranchAvailability(newState)
      .then(() => {
        Toastr.success(MESSAGES.branchStateChange);
        updateBranch({
          ...branch,
          availability: newState
        });
      })
      .catch(error => {
        console.error(error);
        Toastr.error(error);
      });
  }

  render(): React.ReactNode {
    let preppedProducts: ProductsAvailability = {};
    let readyProducts: ProductsAvailability = {};
    const customizations: ProductsAvailability = { extras: [] };

    if (!this.context?.isLoadingMenu) {
      preppedProducts = this.populateProducts(this.context?.preppedProducts);
      readyProducts = this.populateProducts(this.context?.readyProducts);

      for (const extra of this.context?.extras) {
        customizations.extras.push({
          id: extra.id,
          available: extra.available,
          name: extra.name,
          price: extra.price
        });
      }

      for (const variant of this.context?.variants) {
        if (exist(customizations[variant.variant_category?.name])) {
          customizations[variant.variant_category?.name].push(variant);
        } else {
          customizations[variant.variant_category?.name] = [variant];
        }
      }
    }

    return (
      <div className="menu__wrapper">
        <h1 className="menu__header arabic-font">تحديث المنيو</h1>
        {/* <p className="menu__update-time arabic-font">
          أخر تحديث: الأحد 6 مايو 2020, 12:32 PM
        </p> */}
        <div className="tabs__wrapper">
          <div className="tabs__headers arabic-font">
            <ul className="tabs__list general__list">
              <li
                className={
                  this.state.activeProductsType === ProductsType.preppedProducts
                    ? "tabs__list-item tabs__list-item--active"
                    : "tabs__list-item"
                }
                onClick={() => {
                  this.onChangeActiveProductsType(ProductsType.preppedProducts);
                }}
              >
                {SVGs.prepped(
                  this.state.activeProductsType === ProductsType.preppedProducts
                    ? "#FFF6DF"
                    : "#7E4015"
                )}
                المنتجات الجاهزة
              </li>
              <li
                className={
                  this.state.activeProductsType === ProductsType.freshProducts
                    ? "tabs__list-item tabs__list-item--active"
                    : "tabs__list-item"
                }
                onClick={() => {
                  this.onChangeActiveProductsType(ProductsType.freshProducts);
                }}
              >
                {SVGs.fresh(
                  this.state.activeProductsType === ProductsType.freshProducts
                    ? "#FFF6DF"
                    : "#7E4015"
                )}
                منتجات طازجة
              </li>
              <li
                className={
                  this.state.activeProductsType === ProductsType.extraProducts
                    ? "tabs__list-item tabs__list-item--active"
                    : "tabs__list-item"
                }
                onClick={() => {
                  this.onChangeActiveProductsType(ProductsType.extraProducts);
                }}
              >
                {SVGs.extra(
                  this.state.activeProductsType === ProductsType.extraProducts
                    ? "#FFF6DF"
                    : "#7E4015"
                )}
                إضافات
              </li>
            </ul>
            <USER_CONTEXT.Consumer>
              {value => {
                return (
                  <div className="tabs__meta">
                    <span className="state">
                      حالة الفرع:
                      {value.branch?.availability === BranchAvailability.closed
                        ? "مغلق"
                        : "مفتوح"}
                    </span>
                    <img
                      src={
                        value.branch?.availability === BranchAvailability.closed
                          ? ToggleOff
                          : ToggleOn
                      }
                      alt="available"
                      onClick={() => {
                        this.updateBranchAvailability(
                          value.branch?.availability ===
                            BranchAvailability.closed
                            ? BranchAvailability.opened
                            : BranchAvailability.closed,
                          value.branch,
                          value.updateLoggedInBranch
                        );
                      }}
                    />
                  </div>
                );
              }}
            </USER_CONTEXT.Consumer>
          </div>
        </div>
        <div className="tabs__content">
          {this.state.activeProductsType === ProductsType.preppedProducts && (
            <BranchProducts
              header="المنتجات الجاهزة"
              products={preppedProducts}
              listItemsType="product"
              type="preppedProducts"
            />
          )}

          {this.state.activeProductsType === ProductsType.freshProducts && (
            <BranchProducts
              header="منتجات طازجة"
              products={readyProducts}
              listItemsType="product"
              type="readyProducts"
            />
          )}

          {this.state.activeProductsType === ProductsType.extraProducts && (
            <BranchProducts
              header="إضافات"
              products={customizations}
              listItemsType="customization"
              type="customization"
            />
          )}
        </div>
      </div>
    );
  }
}
BranchMenu.contextType = MENU_CONTEXT;
