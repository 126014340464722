/**
 * @author Ahmed Serag
 * @date 2019-07-27
 * @description List item component.
 * @filename list-item.tsx
 */
import React from "react";

export class ListItem extends React.Component<{
  onClick?: () => void;
  active?: boolean;
}> {
  render(): React.ReactNode {
    return (
      <li
        className={`main-list-item${this.props.active ? " active" : ""}`}
        onClick={this.props.onClick}
      >
        {this.props.children}
      </li>
    );
  }
}
