/* eslint-disable jsx-a11y/no-static-element-interactions */
/**
 * @author Raghda wessam
 * @date 2020-07-13
 * @description confirmation popup layout.
 * @filename confirm-box.tsx
 */
import React from "react";
import CloseIcon from "static/images/icons/close.svg";
import { MENU_CONTEXT } from "contexts/menu-context";
/**
 * props used by confirm box component.
 *
 * @export
 */
export interface ConfirmBoxProps {
  /**
   * Function that is fired when confirmation is sucess.
   *
   * @type {*}
   * @memberof ConfirmBoxProps
   */
  onConfirmSuccess: () => void;

  /**
   * Function that is fired when confirmation is declined.
   *
   * @type {*}
   * @memberof ConfirmBoxProps
   */
  onConfirmFail: () => void;

  /**
   * Title for confirmation process.
   *
   * @type {string}
   * @memberof ConfirmBoxProps
   */
  confirmationTitle: string;

  /**
   * A paragraph the user will be prompted for confirmation.
   *
   * @type {string}
   * @memberof ConfirmBoxProps
   */
  confirmationText: string;

  /**
   * text on button to confirm process.
   *
   * @type {string}
   * @memberof ConfirmBoxProps
   */
  confirmProcess: string;

  /**
   * text on button to cancel process
   *
   * @type {string}
   * @memberof ConfirmBoxProps
   */
  cancelProcess: string;

  /**
   * swicth the position of buttons
   *
   * @type {boolean}
   * @memberof ConfirmBoxProps
   */
  switchButtons?: boolean;

  /**
   * a boolean which is true if the cancel order action is disabled
   */
  disabled?: boolean;
}

/**
 * React component responsible for rendering confirmation box layout.
 *
 * @export
 * @extends {React.Component<ConfirmBoxProps>}
 */
export class ConfirmBox extends React.Component<ConfirmBoxProps> {
  declare context: React.ContextType<typeof MENU_CONTEXT>;

  constructor(props: ConfirmBoxProps) {
    super(props);
    this.state = {};
  }

  render(): React.ReactNode {
    return (
      // eslint-disable-next-line react/jsx-no-comment-textnodes
      <div className="confirm__container">
        <div className="confirm__wrapper arabic-font">
          <div className="close-icon" onClick={this.props.onConfirmFail}>
            <img src={CloseIcon} alt="close confirmation" />
          </div>
          <h1 className="confirm__title">{this.props.confirmationTitle}</h1>
          <p className="confirm__description">{this.props.confirmationText}</p>
          <div
            className={
              this.props.switchButtons
                ? "confirm__actions confirm__actions--switch"
                : "confirm__actions"
            }
          >
            <button
              type="button"
              className="button button--primary"
              onClick={this.props.onConfirmSuccess}
              disabled={this.props.disabled ?? false}
            >
              {this.props.confirmProcess}
            </button>
            <button
              type="button"
              className="button button--primary button--cancel"
              onClick={this.props.onConfirmFail}
            >
              {this.props.cancelProcess}
            </button>
          </div>
        </div>
      </div>
    );
  }
}
ConfirmBox.contextType = MENU_CONTEXT;
