/**
 * @author Raghda Wessam
 * @date 2020-07-05
 * @description Select field layout.
 * @filename select-field.tsx
 */
import React from "react";
import Select from "react-select";
import PropTypes from "prop-types";

const SelectField = props => {
  return (
    <div className="select__wrapper arabic-font">
      <h2 className="select__title">{props.label}</h2>
      <Select
        className="react-select-container"
        classNamePrefix="react-select"
        options={props.options}
        placeholder={props.placeholder}
        onChange={props.onChange}
      />
    </div>
  );
};

SelectField.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired
    })
  ).isRequired,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired
};

export default SelectField;
