/**
 * @author Ahmed Serag
 * @date 2020-07-07
 * @description implementation of Product related API calls.
 * @filename product.ts
 */
import { Payload } from "interfaces/payload";
import {
  Product as ProductInterface,
  ProductExtra,
  ProductVariantOption
} from "interfaces/product";
import { exist as _exist } from "utilities/common";
import { Network } from "./network";
import { ENDPOINTS } from "./endpoints";

/**
 * implement Product related calls.
 */
export class Product {
  /**
   *  get all available products.
   */
  public static listProducts(
    prepped?: boolean
  ): Promise<Payload<ProductInterface[]>> {
    return Network.fetch(
      `${process.env.API_ENDPOINT}${ENDPOINTS.listProducts.path}${
        _exist(prepped) ? `?pre_prepared=${prepped ? 1 : 0}` : ""
      }`,
      { method: ENDPOINTS.listProducts.method }
    );
  }

  /**
   *  get all available products customizations.
   */
  public static listProductCustomizations(): Promise<
    Payload<{
      extras: ProductExtra[];
      variants: ProductVariantOption[];
    }>
  > {
    return Network.fetch(
      `${process.env.API_ENDPOINT}${ENDPOINTS.listProductsCustomizations.path}`,
      { method: ENDPOINTS.listProductsCustomizations.method }
    );
  }

  /**
   * update branch products availability.
   *
   * @param added ids of new added products to the branch.
   * @param removed ids of the removed products from the branch.
   */
  public static updateBranchProducts(
    added: string[],
    removed: string[]
  ): Promise<Payload> {
    return Network.fetch(
      `${process.env.API_ENDPOINT}${ENDPOINTS.updateBranchProducts.path}`,
      {
        method: ENDPOINTS.updateBranchProducts.method,
        body: JSON.stringify({
          added,
          removed
        })
      }
    );
  }

  /**
   * update branch products customizations availability.
   *
   * @param extras set of removed and added extras
   * @param variants set of removed and added variants
   */
  public static updateBranchCustomizations(
    extras: {
      added: string[];
      removed: string[];
    },
    variants: {
      added: string[];
      removed: string[];
    }
  ): Promise<Payload> {
    return Network.fetch(
      `${process.env.API_ENDPOINT}${ENDPOINTS.updatedBranchProductsCustomizations.path}`,
      {
        method: ENDPOINTS.updatedBranchProductsCustomizations.method,
        body: JSON.stringify({
          extras,
          variants
        })
      }
    );
  }
}
