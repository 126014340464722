/**
 * @author Ahmed Serag
 * @date 2020-07-05
 * @description implementation of Branch data model interface.
 * @filename branch.ts
 */
import { Common } from "./common";

export interface SpecialDelivery {
  title?: string;
  description?: string;
}

export interface Branch extends Common {
  image: string;
  availability: BranchAvailability;
}

export enum BranchAvailability {
  closed = "CLOSED",
  opened = "OPENED"
}
