/**
 * @author Ahmed Serag
 * @date 2020-07-12
 * @description declaration of login layout.
 * @filename login.tsx
 */
import Toastr from "toastr";
import React from "react";
import { RouteComponentProps, Redirect } from "react-router-dom";
import { Formik, FormikHelpers, FormikProps, Form, Field } from "formik";
import SelectField from "components/common/select-field";
import { ROUTES } from "consts/routes";
import { USER_CONTEXT } from "contexts/user-context";
import { LoginInput } from "interfaces/inputs/login";
import { Branch as BranchInterface } from "interfaces/branch";
import { Branch as BranchUtilities } from "utilities/branch";
import { LOGIN_SCHEMA, User } from "utilities/user";
import LOGO from "static/images/icons/logo-lg.png";

interface LoginState {
  /**
   * available branches to login from.
   */
  branches: BranchInterface[];
  /**
   * a boolean which is true if there is an
   *  active network request loading some resource.
   */
  isLoading: boolean;
}

/**
 * the class responsible for wrapping the Login layout.
 *
 * @export
 * @class Login
 * @extends {React.Component<RouteComponentProps, LoginState>}
 */
export class Login extends React.Component<RouteComponentProps, LoginState> {
  declare context: React.ContextType<typeof USER_CONTEXT>;

  constructor(props: RouteComponentProps) {
    super(props);
    this.state = {
      branches: [],
      isLoading: true
    };
    this.onSubmitLoginForm = this.onSubmitLoginForm.bind(this);
  }

  componentDidMount() {
    BranchUtilities.listBranches()
      .then(branches => {
        this.setState({
          branches,
          isLoading: false
        });
      })
      .catch(errors => {
        Toastr.error(errors);
        this.setState({ isLoading: false });
      });
  }

  /**
   * send request to the backend to login the user and
   * update user context with new logged in branch details.
   * @param values user Input values for login.
   * @param actions set of helpers functions from Formik.
   */
  onSubmitLoginForm(values: LoginInput, actions?: FormikHelpers<LoginInput>) {
    this.setState({ isLoading: true });
    User.login(values)
      .then(branch => {
        this.context.updateLoggedInBranch(branch).then(() => {
          this.props.history.push(ROUTES.BranchMenu.path);
        });
      })
      .catch(errors => {
        console.error(errors);
        actions.setSubmitting(false);
        Toastr.error(errors);
      });
  }

  render(): React.ReactNode {
    if (this.context?.isLoadingBranch) {
      return <div className="loader-active" />;
    }

    if (this.context?.isLoadingBranch) {
      return <Redirect to={ROUTES.Home.path} />;
    }

    const branchesOptions = this.state.branches.map(branch => {
      return {
        value: `${branch.id}`,
        label: branch.name
      };
    });

    return (
      <div className="login__section arabic-font">
        <div className="login__form-wrapper section">
          <Formik
            initialValues={{
              id: "",
              password: ""
            }}
            onSubmit={this.onSubmitLoginForm}
            validationSchema={LOGIN_SCHEMA}
          >
            {(formikBag: FormikProps<LoginInput>) => {
              return (
                <Form>
                  <h1 className="item-title">تسجيل الدخول</h1>
                  <div className="form-field">
                    <SelectField
                      options={branchesOptions}
                      label="إختر الفرع من القائمة"
                      placeholder="الفرع"
                      onChange={selectedBranch => {
                        formikBag.setFieldValue("id", selectedBranch.value);
                      }}
                    />
                  </div>
                  <Field name="password">
                    {({ field, meta }) => (
                      <div className="form-field form-field--password">
                        <label htmlFor="password">أدخل كلمة السر</label>
                        <input
                          id="password"
                          name="password"
                          type="password"
                          placeholder="كلمة السر"
                          autoComplete="off"
                          {...field}
                        />
                        {meta.touched && meta.error && (
                          <div className="error">{meta.error}</div>
                        )}
                      </div>
                    )}
                  </Field>
                  <button
                    className="button button--primary arabic-font"
                    type="submit"
                    disabled={!formikBag.isValid || formikBag.isSubmitting}
                  >
                    تسجيل الدخول
                  </button>
                </Form>
              );
            }}
          </Formik>
        </div>
        <div className="login__image-wrapper section">
          <img src={LOGO} alt="logo" />
        </div>
      </div>
    );
  }
}

Login.contextType = USER_CONTEXT;
