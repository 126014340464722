/**
 * @author Ahmed Serag
 * @date 2020-07-19
 * @description implementation of Order interface.
 * @filename order.ts
 */

import {
  ProductSize,
  ProductVariantOption,
  ProductExtra,
  Product
} from "./product";
import { User } from "./user";

export interface Order {
  id: string;
  items: OrderItem[];
  state: OrderStatus;
  price: OrderPrice;
  branch_id: string;
  paid: boolean;
  created_at: string;
  received_at: string;
  user?: User;
  payment_method: PaymentMethod;
  tax: number;
  total_price: number;
  comment?: string;
  table_number?: string;
  /**
   * level of the gift the order eligible for.
   */
  gift?: number;
  serial?: number;
  discount?: number;
  discount_percentage?: number;
  additional_info?: Record<string, any>;
  promocode?: string;
  promocode_id?: number;
  promocode_percentage?: number;
  promocode_discount_amount?: number;
  subscription_discount?: number;
  has_subscription_item?: boolean;
}

export interface OrderItem {
  id: number;
  product: Product;
  price: number;
  quantity: number;
  size: ProductSize;
  variants?: ProductVariantOption[];
  extras?: ProductExtra[];
  is_free_gift?: boolean;
}

export interface OrderPrice {
  subTotal: string;
  vat: string;
  tax: string;
  total: string;
}

export enum OrderStatus {
  draft = "draft",
  received = "received",
  confirmed = "confirmed",
  prepared = "prepared",
  picked = "picked",
  canceled = "canceled"
}

export const ArabicOrderStatus = {
  draft: "طلب جديد",
  received: "طلب جديد",
  confirmed: "طلب جديد",
  prepared: "طلب جديد",
  picked: "طلب جديد"
};

export enum PaymentMethod {
  card = "creditCard",
  cash = "cash"
}
