import AIR_HORN from "static/sounds/Notification.mp3";

class Notification {
  private static instance: Notification;

  private notificationAudio: HTMLAudioElement;

  private constructor() {
    this.notificationAudio = new Audio(AIR_HORN);
  }

  public static getInstance(): Notification {
    Notification.instance = Notification.instance || new Notification();
    return Notification.instance;
  }

  public getAudioNotification(): HTMLAudioElement {
    return this.notificationAudio;
  }
}

export default Notification;
