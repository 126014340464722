/**
 * @author Ahmed Serag
 * @date 2019-07-15
 * @description start point of the react application that uses
 * react dom to manipulate the root div.
 * @filename index.tsx
 */
import * as React from "react";
import LogRocket from "logrocket";
import setupLogRocketReact from "logrocket-react";
import Toastr from "toastr";
import * as ReactDOM from "react-dom";
import * as Sentry from "@sentry/react";
import { BrowserRouter, Route, Switch, RouteProps } from "react-router-dom";
import { NotFound } from "layouts/not-found";
import { MENU_CONTEXT } from "contexts/menu-context";
import { USER_CONTEXT } from "contexts/user-context";
import { Branch as BranchInterface } from "interfaces/branch";
import { Order as OrderInterface, OrderStatus } from "interfaces/order";
import { Order as OrderUtilities } from "utilities/order";
import Notification from "utilities/notification";
import {
  Product as ProductInterface,
  ProductExtra as ProductExtraInterface,
  ProductVariantOption as ProductVariantOptionInterface
} from "interfaces/product";
import { User as UserUtilities } from "utilities/user";
import { Product as ProductUtilities } from "utilities/product";
import { ORDERS_CONTEXT } from "contexts/orders-context";
import { Sidebar } from "./react-components/common/sidebar";
import { ROUTES } from "./definitions/consts/routes";

/**
 * state of application component.
 *
 * @interface AppState
 */
interface AppState {
  /**
   * id of the Active branch.
   */
  activeBranchId?: string;
  /**
   * list of prepped product in the menu.
   */
  preppedProducts?: ProductInterface[];
  /**
   * list of ready products in the menu.
   */
  readyProducts?: ProductInterface[];
  /**
   * set of product extras in the menu.
   */
  extras?: ProductExtraInterface[];
  /**
   * set of product variants options in the menu.
   */
  variants?: ProductVariantOptionInterface[];
  /**
   * current Logged In branch;
   */
  branch?: BranchInterface;
  /**
   * a boolean which is true if loading current loggedIn Branch.
   */
  isLoadingBranch: boolean;
  /**
   * a boolean which is true if the menu is loading.
   */
  isLoadingMenu: boolean;
  /**
   * orders that are received but not confirmed yet.
   */
  newOrders: OrderInterface[];
  /**
   * orders that are confirmed and being prepared.
   */
  confirmedOrder: OrderInterface[];
  /**
   * orders that are prepared and ready to pick-up.
   */
  preparedOrders: OrderInterface[];
  /**
   * a record for all orders to indicate its loading  status.
   */
  LoadingOrders: Record<OrderStatus, boolean>;
}

/**
 * the Start point of the project.
 *
 * @class App
 * @extends {React.Component<{}, AppState>}
 */
class App extends React.Component<unknown, AppState> {
  loadOrderTask: NodeJS.Timeout;

  toastrAudio: HTMLAudioElement;

  constructor(props: unknown) {
    super(props);
    this.toastrAudio = Notification.getInstance().getAudioNotification();
    this.state = {
      isLoadingBranch: true,
      isLoadingMenu: true,
      LoadingOrders: {
        confirmed: false,
        draft: false,
        picked: false,
        prepared: false,
        received: false,
        canceled: false
      },
      confirmedOrder: [],
      newOrders: [],
      preparedOrders: []
    };
    this.updateMenu = this.updateMenu.bind(this);
    this.loadMenu = this.loadMenu.bind(this);
    this.updateLoggedInBranch = this.updateLoggedInBranch.bind(this);
    this.loadOrders = this.loadOrders.bind(this);
    this.postLogin = this.postLogin.bind(this);
  }

  componentDidMount() {
    UserUtilities.getLoggedInUser()
      .then(branch => {
        LogRocket.identify(branch.id, {
          name: branch.name,
          token: localStorage.getItem(process.env.ACCESS_TOKEN_KEY)
        });
        this.setState(
          {
            branch,
            isLoadingBranch: false
          },
          () => {
            this.postLogin();
          }
        );
      })
      .catch(() => {
        this.setState({
          isLoadingBranch: false,
          isLoadingMenu: false
        });
      });
  }

  componentWillUnmount() {
    clearInterval(this.loadOrderTask);
  }

  postLogin() {
    this.loadMenu();
    this.loadOrders();
    if (!this.loadOrderTask) {
      this.loadOrderTask = setInterval(() => {
        this.loadOrders();
      }, 15000);
    }
  }

  loadOrders() {
    this.setState({
      LoadingOrders: {
        confirmed: true,
        draft: false,
        picked: true,
        prepared: true,
        received: false,
        canceled: false
      }
    });
    OrderUtilities.lisOrders()
      .then(orders => {
        const newOrders: OrderInterface[] = [];
        const confirmedOrder: OrderInterface[] = [];
        const preparedOrders: OrderInterface[] = [];
        const previousOrders = this.state.newOrders;
        let newOrderFlag = false;

        for (const order of orders) {
          switch (order.state) {
            case OrderStatus.received:
              newOrders.push(order);
              if (!newOrderFlag) {
                newOrderFlag = !previousOrders.find(o => o.id === order.id);
              }
              break;
            case OrderStatus.confirmed:
              confirmedOrder.push(order);
              break;
            case OrderStatus.prepared:
              preparedOrders.push(order);
              break;
            default:
              break;
          }
        }

        if (newOrderFlag) {
          Toastr.clear();
          Toastr.options.closeButton = true;
          if (newOrders.length > 0) {
            Toastr.info("يوجد طلب جديد", "", {
              closeButton: true,
              // positionClass: "toast-top-full-width",
              timeOut: 0,
              hideDuration: 0,
              extendedTimeOut: 0,
              tapToDismiss: false
            });
            try {
              this.toastrAudio.currentTime = 0;
              this.toastrAudio.play();
            } catch (error) {
              const sentryEvent: Sentry.Event = {
                message: "sound notification",
                environment: `${process.env.sentry_environment}`
              };
              Sentry.captureEvent(sentryEvent);
            }
          } else {
            Toastr.clear();
          }
        }

        this.setState({
          newOrders,
          confirmedOrder,
          preparedOrders,
          LoadingOrders: {
            confirmed: false,
            draft: false,
            picked: false,
            prepared: false,
            received: false,
            canceled: false
          }
        });
      })
      .catch(error => {
        Toastr.error(error);
        console.error(error);
        this.setState({
          LoadingOrders: {
            confirmed: false,
            draft: false,
            picked: false,
            prepared: false,
            received: false,
            canceled: false
          }
        });
      });
  }

  /**
   * load branch menu.
   */
  loadMenu() {
    this.setState({ isLoadingMenu: true });
    const promises: Promise<unknown>[] = [];
    // Fetch prepped products
    promises.push(
      ProductUtilities.listProducts(true).then(preppedProducts => {
        this.setState({ preppedProducts });
        return preppedProducts;
      })
    );
    // Fetch ready products
    promises.push(
      ProductUtilities.listProducts(false).then(readyProducts => {
        this.setState({ readyProducts });
        return readyProducts;
      })
    );

    // Fetch Product customizations
    promises.push(
      ProductUtilities.listProductsCustomizations().then(customizations => {
        this.setState({
          variants: customizations.variants,
          extras: customizations.extras
        });
        return customizations;
      })
    );

    Promise.all(promises)
      .then(() => {
        this.setState({ isLoadingMenu: false });
      })
      .catch(error => {
        Toastr.error(error);
        console.error(error);
        this.setState({ isLoadingMenu: false });
      });
  }

  /**
   * update current logged in branch
   * @param branch new logged in branch
   */
  updateLoggedInBranch(branch: BranchInterface): Promise<unknown> {
    LogRocket.identify(branch.id, {
      name: branch.name,
      token: localStorage.getItem(process.env.ACCESS_TOKEN_KEY)
    });
    return new Promise<void>(resolve => {
      this.setState({ branch }, () => {
        this.postLogin();
        resolve();
      });
    });
  }

  /**
   * update current menu with new branch and it's new menu.
   *
   * @param branchId id of the new branch.
   * @param menu new menu to be added to the app
   */
  updateMenu(
    branchId: string,
    menu: {
      preppedProducts?: ProductInterface[];
      readyProducts?: ProductInterface[];
      extras?: ProductExtraInterface[];
      variants?: ProductVariantOptionInterface[];
    }
  ) {
    this.setState({
      ...menu,
      activeBranchId: branchId
    });
  }

  render(): React.ReactNode {
    const availableRoutes = Object.keys(ROUTES).reduce((prev, cur) => {
      if (
        !this.state.isLoadingBranch &&
        !this.state.branch &&
        !ROUTES[cur].public
      ) {
        return prev;
      }

      const Component = ROUTES[cur].component;
      return [
        ...prev,
        <Route
          key={ROUTES[cur].path}
          path={ROUTES[cur].path}
          exact={ROUTES[cur].exact}
          render={(renderProps: RouteProps) => (
            <Component {...renderProps} {...ROUTES[cur].props} />
          )}
        />
      ];
    }, []);

    return (
      <USER_CONTEXT.Provider
        value={{
          branch: this.state.branch,
          isLoadingBranch: this.state.isLoadingBranch,
          updateLoggedInBranch: this.updateLoggedInBranch
        }}
      >
        <div
          id="main-content"
          className={this.state.branch ? "main-content" : "regular-content"}
        >
          <MENU_CONTEXT.Provider
            value={{
              branchId: this.state.activeBranchId,
              extras: this.state.extras,
              preppedProducts: this.state.preppedProducts,
              readyProducts: this.state.readyProducts,
              variants: this.state.variants,
              isLoadingMenu: this.state.isLoadingMenu,
              updateMenu: this.updateMenu
            }}
          >
            <ORDERS_CONTEXT.Provider
              value={{
                LoadingOrders: this.state.LoadingOrders,
                confirmedOrder: this.state.confirmedOrder,
                newOrders: this.state.newOrders,
                preparedOrders: this.state.preparedOrders,
                populateOrders: this.loadOrders
              }}
            >
              <BrowserRouter>
                {this.state.branch && (
                  <Route
                    key="side-bar"
                    render={renderProps => <Sidebar {...renderProps} />}
                  />
                )}
                <Switch>
                  {availableRoutes}
                  <Route key="not-found" render={() => <NotFound />} />
                </Switch>
              </BrowserRouter>
            </ORDERS_CONTEXT.Provider>
          </MENU_CONTEXT.Provider>
        </div>
      </USER_CONTEXT.Provider>
    );
  }
}

Sentry.init({
  dsn: process.env.SENTRY_DSN,
  autoSessionTracking: true
});

LogRocket.init("knpkbn/cilantro");
setupLogRocketReact(LogRocket);

LogRocket.getSessionURL(sessionURL => {
  Sentry.configureScope(scope => {
    scope.setExtra("sessionURL", sessionURL);
  });
});
/**
 * The application.
 *
 * @type {(void|Element|React.Component<*, React.ComponentState, *>)}
 */
ReactDOM.render(<App />, document.getElementById("root"));
