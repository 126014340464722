/**
 * @author Ahmed Serag
 * @date 2019-07-16
 * @description implementation of endpoints in the api.
 * @filename endpoints.ts
 */

/**
 * set of endpoints in the API.
 */
// eslint-disable-next-line object-curly-newline
export const ENDPOINTS = {
  login: {
    path: "/barista/branches/login",
    method: "POST"
  },
  logout: {
    path: "/barista/branches/logout",
    method: "POST"
  },
  currentBranch: {
    path: "/barista/branches/current",
    method: "GET"
  },
  setBranchAvailability: {
    path: "/barista/branches/availability",
    method: "PATCH"
  },
  listBranches: {
    path: "/branches",
    method: "GET"
  },
  listProducts: {
    path: "/barista/products",
    method: "GET"
  },
  listProductsCustomizations: {
    path: "/barista/products/customization",
    method: "GET"
  },
  updateBranchProducts: {
    path: "/barista/branches/products",
    method: "PATCH"
  },
  updatedBranchProductsCustomizations: {
    path: "/barista/branches/products/customization",
    method: "PATCH"
  },
  listOrders: {
    path: "/barista/orders",
    method: "GET"
  },
  orderDetails: {
    path: (orderId: string) => `/barista/orders/${orderId}`,
    method: "GET"
  },
  confirmOrder: {
    path: (orderId: string) => `/barista/orders/${orderId}/confirm`,
    method: "PATCH"
  },
  cancelOrder: {
    path: (orderId: string) => `/barista/orders/${orderId}/cancel`,
    method: "PATCH"
  },
  refundOrder: {
    path: (orderId: string) => `/barista/orders/${orderId}/refund`,
    method: "PATCH"
  },
  readyOrder: {
    path: (orderId: string) => `/barista/orders/${orderId}/ready`,
    method: "PATCH"
  },
  pickOrder: {
    path: (orderId: string) => `/barista/orders/${orderId}/pick`,
    method: "PATCH"
  }
};
