/**
 * @author Ahmed Serag
 * @date 2020-07-04
 * @description implementation of Branches related API calls.
 * @filename branch.ts
 */

import { Payload } from "interfaces/payload";
import {
  Branch as BranchInterface,
  BranchAvailability
} from "interfaces/branch";
import { Network } from "./network";
import { ENDPOINTS } from "./endpoints";

/**
 * implement network related calls.
 */
export class Branch {
  /**
   *  list available branches from API.
   *
   * @static
   * @returns {Promise<Payload<Branch>>} Payload of available branches in the API.
   */
  public static listBranches(): Promise<Payload<BranchInterface[]>> {
    return Network.fetch(
      `${process.env.API_ENDPOINT}${ENDPOINTS.listBranches.path}`,
      { method: ENDPOINTS.listBranches.method }
    );
  }

  public static setBranchAvailability(
    state: BranchAvailability
  ): Promise<Payload<unknown>> {
    return Network.fetch(
      `${process.env.API_ENDPOINT}${ENDPOINTS.setBranchAvailability.path}`,
      {
        method: ENDPOINTS.setBranchAvailability.method,
        body: JSON.stringify({ state })
      }
    );
  }
}
