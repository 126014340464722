/**
 * @author Ahmed Serag
 * @date 2020-07-12
 * @description implementation of authentication related API calls.
 * @filename authentication.ts
 */
import { Payload } from "interfaces/payload";
import { LoginInput } from "interfaces/inputs/login";
import { exist as _exist } from "utilities/common";
import { Branch as BranchInterface } from "interfaces/branch";
import { Network } from "./network";
import { ENDPOINTS } from "./endpoints";

/**
 * implement network related calls.
 */
export class Authentication {
  /**
   * call Login endpoint and set user authentication keys in local storage.
   *
   * @param input branch credentials input.
   */
  public static login(input: LoginInput): Promise<Payload> {
    let promise = Network.fetch(
      `${process.env.API_ENDPOINT}${ENDPOINTS.login.path}`,
      {
        method: ENDPOINTS.login.method,
        body: JSON.stringify(input)
      }
    );

    promise = promise.then(payload => {
      if (_exist(payload?.data, ["access_token"])) {
        localStorage.setItem(
          process.env.ACCESS_TOKEN_KEY,
          payload.data.access_token
        );
      }

      return payload;
    });

    return promise;
  }

  /**
   * call logout API to invalidate the token and remove it from local-storage/
   */
  public static logout(): Promise<Payload> {
    let promise = Network.fetch(
      `${process.env.API_ENDPOINT}${ENDPOINTS.logout.path}`,
      { method: ENDPOINTS.logout.method }
    );

    promise = promise.then(payload => {
      localStorage.removeItem(process.env.ACCESS_TOKEN_KEY);
      return payload;
    });

    return promise;
  }

  /**
   * fetch current loggedIn branch details
   */
  public static currentBranch(): Promise<Payload<BranchInterface>> {
    if (!localStorage.getItem(process.env.ACCESS_TOKEN_KEY)) {
      return Promise.reject();
    }

    return Network.fetch(
      `${process.env.API_ENDPOINT}${ENDPOINTS.currentBranch.path}`,
      { method: ENDPOINTS.currentBranch.method }
    );
  }
}
