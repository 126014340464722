/**
 * @author Raghda Wessam
 * @date 2020-07-05
 * @description Primary Button layout.
 * @filename primary-button.tsx
 */
import React, { FunctionComponent, ReactElement } from "react";

export interface PrimaryButtonProps {
  title: string;
  onClick: () => void;
  disabled?: boolean;
}

const PrimaryButton: FunctionComponent<PrimaryButtonProps> = (
  props: PrimaryButtonProps
): ReactElement => {
  return (
    <div className="button__wrapper">
      <button
        className="button--primary arabic-font"
        type="button"
        onClick={props.onClick}
        disabled={props.disabled ?? false}
      >
        {props.title}
      </button>
    </div>
  );
};

export default PrimaryButton;
