/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/anchor-is-valid */
/**
 * @author Raghda Wessam
 * @date 2020-07-12
 * @description App sidebar.
 * @filename sidebar.tsx
 */
import React from "react";
import Toastr from "toastr";
import cilantroLogo from "static/images/icons/logo.png";
import updateIcon from "static/images/icons/stock.svg";
import progressIcon from "static/images/icons/cup.svg";
// import finishedIcon from "static/images/icons/history.svg";
import logOffIcon from "static/images/icons/log-off.svg";
// import cancelledIcon from "static/images/icons/cancelled.svg";
import { Authentication as AuthenticationUtilities } from "api/authentication";
import { RouteComponentProps } from "react-router-dom";
import { USER_CONTEXT } from "contexts/user-context";
import { ROUTES } from "../../../definitions/consts/routes";
import { ConfirmBox } from "../confirm-box";

export class Sidebar extends React.Component<
  RouteComponentProps,
  {
    logoutModalActive: boolean;
    menuExpanded: boolean;
  }
> {
  declare context: React.ContextType<typeof USER_CONTEXT>;

  constructor(props) {
    super(props);
    this.state = { logoutModalActive: false, menuExpanded: true };
    this.onConfirmLogout = this.onConfirmLogout.bind(this);
  }

  componentDidMount() {
    this.setState({ menuExpanded: true });
  }

  onConfirmLogout() {
    AuthenticationUtilities.logout()
      .then(() => {
        window.location.replace(ROUTES.Login.path);
      })
      .catch(e => {
        Toastr.error(e);
        this.setState({ logoutModalActive: false });
      });
  }

  render(): React.ReactNode {
    const { menuExpanded } = this.state;
    return (
      <>
        <nav
          className={
            this.state.menuExpanded
              ? "nav__container arabic-font"
              : "nav__container arabic-font nav__container--collapse"
          }
          onClick={() => {
            this.setState({ menuExpanded: !menuExpanded });
            if (
              document
                .getElementById("main-content")
                .classList.contains("main-content")
            ) {
              document
                .getElementById("main-content")
                .classList.toggle("main-content--collapse");
            }
          }}
        >
          <ul className="nav__list general__list">
            <li className="nav__list-item nav__list-item--header">
              <div className="nav__link">
                <img src={cilantroLogo} alt="cilantro logo" />
              </div>
              <p className="nav__location">{this.context.branch?.name}</p>
            </li>
            <li className="nav__list-item nav__list-item--update">
              <a
                onClick={() => {
                  this.props.history.push(ROUTES.BranchMenu.path);
                }}
                className={
                  window.location.pathname === ROUTES.BranchMenu.path
                    ? "nav__link nav__link--active"
                    : "nav__link"
                }
              >
                <img src={updateIcon} alt="update icon" />
                <span className="nav__link--name"> تحديث المنيو</span>
              </a>
            </li>
            <li className="nav__list-item nav__list-item--subheader">
              الطلبات
            </li>
            <li className="nav__list-item">
              <a
                onClick={() => {
                  this.props.history.push(ROUTES.ordersListing.path);
                }}
                className={
                  window.location.pathname === ROUTES.ordersListing.path
                    ? "nav__link nav__link--active"
                    : "nav__link"
                }
              >
                <img src={progressIcon} alt="progress icon" />
                <span className="nav__link--name"> جارية التنفيذ</span>
              </a>
            </li>
            {/* <li className="nav__list-item">
              <a className="nav__link">
                <img src={finishedIcon} alt="completed icon" />
                <span className="nav__link--name">اكتملت</span>
              </a>
            </li>
            <li className="nav__list-item">
              <a className="nav__link">
                <img src={cancelledIcon} alt="cancelled icon" />
                <span className="nav__link--name">ملغية</span>
              </a>
            </li> */}
            <li className="nav__list-item--logout">
              <button
                type="button"
                className="nav__link arabic-font"
                onClick={() => this.setState({ logoutModalActive: true })}
              >
                <img src={logOffIcon} alt="log off icon" />
                <span className="nav__link--name"> تسجيل الخروج</span>
              </button>
            </li>
          </ul>
        </nav>
        {this.state.logoutModalActive && (
          <ConfirmBox
            onConfirmSuccess={this.onConfirmLogout}
            onConfirmFail={() => this.setState({ logoutModalActive: false })}
            confirmationTitle="تأكيد الخروج"
            confirmationText="هل أنت متأكد أنك تريد الخروج ؟"
            confirmProcess="تأكيد"
            cancelProcess="إلغاء"
          />
        )}
      </>
    );
  }
}
Sidebar.contextType = USER_CONTEXT;
