/**
 * @author Ahmed Serag
 * @date 2020-07-12
 * @description Implementation of user related store data.
 * @filename user-context.ts
 */
import * as React from "react";
import { Branch } from "interfaces/branch";

/**
 * User Context holding logged in Branch details
 */
export const USER_CONTEXT: React.Context<{
  /**
   * logged In branch details
   */
  branch: Branch;
  /**
   * a function to update current logged in branch
   */
  updateLoggedInBranch: (branch: Branch) => Promise<unknown>;
  /**
   * a boolean which is true if loading current logged in branch details.
   */
  isLoadingBranch: boolean;
}> = React.createContext({
  branch: null,
  updateLoggedInBranch: null,
  isLoadingBranch: null
});
