/**
 * @author Ahmed Serag
 * @date 2019-07-27
 * @description List component.
 * @filename list.tsx
 */
import React from "react";

export class List extends React.Component<unknown> {
  render(): React.ReactNode {
    return <ul className="main-list">{this.props.children}</ul>;
  }
}
