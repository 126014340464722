/**
 * @author Ahmed Serag
 * @date 2020-07-28
 * @description Implementation of Orders context that holds Branch active orders.
 * @filename orders-context.ts
 */
import * as React from "react";
import { Order, OrderStatus } from "interfaces/order";

/**
 * Order context that holds Branch active orders.
 */
export const ORDERS_CONTEXT: React.Context<{
  /**
   * orders that are received but not confirmed yet.
   */
  newOrders: Order[];
  /**
   * orders that are confirmed and being prepared.
   */
  confirmedOrder: Order[];
  /**
   * orders that are prepared and ready to pick-up.
   */
  preparedOrders: Order[];
  /**
   * fetch orders of a specific status.
   */
  populateOrders: (status?: OrderStatus) => void;
  /**
   * a record for all orders to indicate its loading  status.
   */
  LoadingOrders: Record<OrderStatus, boolean>;
}> = React.createContext({
  newOrders: null,
  confirmedOrder: null,
  preparedOrders: null,
  populateOrders: null,
  LoadingOrders: null
});
