/**
 * @author Ahmed Serag
 * @date 2019-07-15
 * @description declaration of available routes in the app.
 * @filename routes.ts
 */
import { Login } from "layouts/login";
import { BranchMenu } from "layouts/branch-menu";
import { OrdersListing } from "layouts/orders-listing";

/**
 * map of available routes in the app to their locations in the url.
 */
export const ROUTES = {
  // Add Available Routes with it's Components and other details
  Home: {
    path: "/",
    component: Login,
    exact: true,
    public: true
  },
  BranchMenu: {
    component: BranchMenu,
    path: "/menu",
    exact: true,
    public: false
  },
  ordersListing: {
    component: OrdersListing,
    path: "/orders",
    public: false,
    exact: true
  },
  Login: {
    component: Login,
    path: "/login",
    public: true,
    exact: true
  }
};
