/**
 * @author Ahmed Serag
 * @date 2020-07-05
 * @description Implementation of Branches related utilities.
 * @filename branch.ts
 */
import { Branch as BranchAPI } from "api/branch";
import {
  Branch as BranchInterface,
  BranchAvailability
} from "interfaces/branch";
import {
  getPayloadData as _getPayloadData,
  handleError as _handleError
} from "./common";

/**
 * group of Branches helpers functionalities.
 */
export class Branch {
  /**
   * list all available branches.
   */
  public static listBranches(): Promise<BranchInterface[]> {
    return BranchAPI.listBranches()
      .then(result => {
        return _getPayloadData(result);
      })
      .catch(error => {
        return Promise.reject(_handleError(error));
      });
  }

  public static setBranchAvailability(
    state: BranchAvailability
  ): Promise<unknown> {
    return BranchAPI.setBranchAvailability(state)
      .then(result => {
        return _getPayloadData(result);
      })
      .catch(error => {
        return Promise.reject(_handleError(error));
      });
  }
}
