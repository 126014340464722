/**
 * @author Ahmed Serag
 * @date 2020-07-28
 * @description implementation of Order related API calls.
 * @filename order.ts
 */
import { Payload } from "interfaces/payload";
import { Order as OrderInterface, PaymentMethod } from "interfaces/order";
import { Network } from "./network";
import { ENDPOINTS } from "./endpoints";

/**
 * implement Order related calls.
 */
export class Order {
  /**
   *  get all available orders.
   */
  public static listOrders(): Promise<Payload<OrderInterface[]>> {
    return Network.fetch(
      `${process.env.API_ENDPOINT}${ENDPOINTS.listOrders.path}`,
      { method: ENDPOINTS.listProducts.method }
    );
  }

  /**
   * get specific order details.
   * @param orderId id of hte order
   */
  public static getOrderDetails(
    orderId: string
  ): Promise<Payload<OrderInterface>> {
    return Network.fetch(
      `${process.env.API_ENDPOINT}${ENDPOINTS.orderDetails.path(orderId)}`,
      { method: ENDPOINTS.orderDetails.method }
    );
  }

  /**
   * confirm an order from the barista.
   * @param orderId id of hte order
   */
  public static confirmOrder(
    orderId: string
  ): Promise<Payload<OrderInterface>> {
    return Network.fetch(
      `${process.env.API_ENDPOINT}${ENDPOINTS.confirmOrder.path(orderId)}`,
      { method: ENDPOINTS.confirmOrder.method }
    );
  }

  /**
   * cancel an order from the barista.
   * @param orderId id of the order
   */
  public static cancelOrder(
    orderId: string,
    paymentMethod: PaymentMethod
  ): Promise<Payload<OrderInterface>> {
    if (paymentMethod === PaymentMethod.card) {
      return Network.fetch(
        `${process.env.API_ENDPOINT}${ENDPOINTS.refundOrder.path(orderId)}`,
        { method: ENDPOINTS.refundOrder.method }
      );
    }

    return Network.fetch(
      `${process.env.API_ENDPOINT}${ENDPOINTS.cancelOrder.path(orderId)}`,
      { method: ENDPOINTS.cancelOrder.method }
    );
  }

  /**
   * mark order as ready
   * @param orderId id of hte order
   */
  public static readyOrder(orderId: string): Promise<Payload<OrderInterface>> {
    return Network.fetch(
      `${process.env.API_ENDPOINT}${ENDPOINTS.readyOrder.path(orderId)}`,
      { method: ENDPOINTS.readyOrder.method }
    );
  }

  /**
   * mark order as picked
   * @param orderId id of hte order
   */
  public static pickOrder(orderId: string): Promise<Payload<OrderInterface>> {
    return Network.fetch(
      `${process.env.API_ENDPOINT}${ENDPOINTS.pickOrder.path(orderId)}`,
      { method: ENDPOINTS.pickOrder.method }
    );
  }
}
