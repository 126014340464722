/**
 * @author Ahmed Serag
 * @date 2020-07-07
 * @description Implementation of Product related utilities.
 * @filename product.ts
 */
import { Product as ProductAPI } from "api/product";
import {
  Product as ProductInterface,
  ProductExtra as ProductExtraInterface,
  ProductVariantOption as ProductVariantOptionInterface
} from "interfaces/product";
import { ERRORS } from "consts/errors";
import {
  getPayloadData as _getPayloadData,
  handleError as _handleError,
  isEmpty as _isEmpty
} from "./common";

/**
 * group of Product helpers functionalities.
 */
export class Product {
  /**
   * list all available products with an option to list only specific type.
   * @param prepped a boolean to be used to get either
   * prepped or ready products.
   */
  public static listProducts(prepped?: boolean): Promise<ProductInterface[]> {
    return ProductAPI.listProducts(prepped)
      .then(result => {
        return _getPayloadData(result);
      })
      .catch(error => {
        return Promise.reject(_handleError(error));
      });
  }

  /**
   * get a list of available extras and variants in the menu.
   */
  public static listProductsCustomizations(): Promise<{
    extras: ProductExtraInterface[];
    variants: ProductVariantOptionInterface[];
  }> {
    return ProductAPI.listProductCustomizations()
      .then(result => {
        return _getPayloadData(result);
      })
      .catch(error => {
        return Promise.reject(_handleError(error));
      });
  }

  /**
   * check for added or removed products
   * then updates the API with it.
   *
   * @param added ids of new added products to the branch.
   * @param removed ids of the removed products from the branch.
   */
  public static updateProducts(
    added: string[],
    removed: string[]
  ): Promise<unknown> {
    if (_isEmpty(added) && _isEmpty(removed)) {
      return Promise.reject(ERRORS.updatedProducts.empty);
    }

    return ProductAPI.updateBranchProducts(added, removed)
      .then(result => {
        return _getPayloadData(result);
      })
      .catch(error => {
        return Promise.reject(_handleError(error));
      });
  }

  /**
   * check for added or removed products customizations
   * then updates the API with it.
   *
   * @param extras set of removed and added extras
   * @param variants set of removed and added variants
   */
  public static updateProductsCustomizations(
    extras: {
      added: string[];
      removed: string[];
    },
    variants: {
      added: string[];
      removed: string[];
    }
  ): Promise<unknown> {
    if (
      _isEmpty(extras?.added) &&
      _isEmpty(extras?.removed) &&
      _isEmpty(variants?.added) &&
      _isEmpty(variants?.removed)
    ) {
      return Promise.reject(ERRORS.updatedProducts.empty);
    }

    return ProductAPI.updateBranchCustomizations(extras, variants)
      .then(result => {
        return _getPayloadData(result);
      })
      .catch(error => {
        return Promise.reject(_handleError(error));
      });
  }
}
