/**
 * @author Ahmed Serag
 * @date 2020-07-12
 * @description implementation of User related utilities.
 * @filename user.ts
 */
import * as Sentry from "@sentry/react";
import * as YUP from "yup";
import { ERRORS } from "consts/errors";
import { Branch } from "interfaces/branch";
import { Authentication } from "api/authentication";
import { LoginInput } from "interfaces/inputs/login";
import {
  getPayloadData as _getPayloadData,
  handleError as _handleError
} from "./common";

/**
 * schema to validate the fields for login.
 */
export const LOGIN_SCHEMA: YUP.ObjectSchema = YUP.object().shape({
  id: YUP.string().required("Branch is a required field"),
  password: YUP.string()
    .min(4, "يجب أن تكون كلمة المرور 4 أحرف على الأقل")
    .required("يرجى إدخال كلمة المرور")
});

/**
 * a class that contains user related utilities.
 *
 * @export
 * @class User
 */
export class User {
  /**
   * send a request to the api to login user and
   * set user authentication details in the local storage.
   *
   * @static
   * @param {string} email email of the user to login with.
   * @param {string} password password of the user to login with.
   * @returns {Promise<CorporateUserInterface>} a promise holding logged in CorporateUser
   * or reject with an array of Errors returned from the API if found.
   * @memberof User
   */
  public static login(input: LoginInput): Promise<Branch> {
    let promise = LOGIN_SCHEMA.isValid(input).then(
      (valid: boolean): Promise<unknown> => {
        return valid ? Promise.resolve() : Promise.reject(ERRORS.validation);
      }
    );

    promise = promise.then(() => {
      return Authentication.login(input);
    });

    return promise
      .then(result => {
        // TODO return only branch details!
        return _getPayloadData(result);
      })
      .catch(error => {
        const sentryEvent: Sentry.Event = {
          message: "Login Error",
          environment: `${process.env.sentry_environment}`,
          contexts: { error: { error } }
        };
        Sentry.captureEvent(sentryEvent);
        return Promise.reject(_handleError(error));
      });
  }

  /**
   * return promise to get the loggedIn CorporateUser if found.
   *
   * @static
   * @returns {Promise<CorporateUserInterface>}
   * @memberof User
   */
  public static getLoggedInUser(): Promise<Branch> {
    return Authentication.currentBranch()
      .then(result => {
        return _getPayloadData(result);
      })
      .catch(error => {
        return Promise.reject(_handleError(error));
      });
  }

  /**
   * log the user out of the system.
   */
  public static logout(): Promise<unknown> {
    return Authentication.logout()
      .then(result => {
        // TODO return only branch details!
        return _getPayloadData(result);
      })
      .catch(error => {
        return Promise.reject(_handleError(error));
      });
  }
}
