/**
 * @author Ahmed Serag
 * @date 2020-07-21
 * @description declaration of available messages in the app.
 * @filename errors.ts
 */

/**
 * set of available messages in the App.
 */
export const MESSAGES = { branchStateChange: "تم تغيير حالة الفرع بنجاح." };
