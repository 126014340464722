/**
 * @author Ahmed Serag
 * @date 2019-07-27
 * @description orders listing Layout.
 * @filename orders-listing.tsx
 */
import React from "react";
import Toastr from "toastr";
import { RouteComponentProps } from "react-router-dom";
import { List } from "components/common/list";
import { ListItem } from "components/common/list-item";
import { ORDERS_CONTEXT } from "contexts/orders-context";
import {
  Order as OrderInterface,
  ArabicOrderStatus,
  OrderStatus,
  PaymentMethod
} from "interfaces/order";
import { Order as OrderUtilities } from "utilities/order";
import { getTimeDistance as _getTimeDistance } from "utilities/common";
import { SubOrderDetails } from "./sub-order-details";

export interface OrdersListingState {
  activeTab: "new" | "confirmed" | "ready";
  activeOrder?: OrderInterface;
}

export class OrdersListing extends React.Component<
  RouteComponentProps,
  OrdersListingState
> {
  declare context: React.ContextType<typeof ORDERS_CONTEXT>;

  toastrAudio: HTMLAudioElement;

  constructor(props) {
    super(props);
    this.state = { activeTab: "new" };
    this.updateOrderState = this.updateOrderState.bind(this);
    this.onSelectOrder = this.onSelectOrder.bind(this);
  }

  /**
   * fetch order details and update current active order state.
   * @param orderId id of the order.
   */
  onSelectOrder(orderId: string) {
    OrderUtilities.getOrderDetails(orderId)
      .then((activeOrder: OrderInterface) => {
        this.setState({ activeOrder });
      })
      .catch(e => {
        Toastr.error(e);
      });
  }

  /**
   * update order to new state.
   * @param orderId id of the order to be updated
   * @param state new state of the order.
   */
  updateOrderState(
    orderId: string,
    state: OrderStatus,
    paymentMethod: PaymentMethod
  ): Promise<void> {
    let promise: Promise<OrderInterface>;

    switch (state) {
      case OrderStatus.confirmed:
        promise = OrderUtilities.confirmOrder(orderId);
        break;
      case OrderStatus.prepared:
        promise = OrderUtilities.readyOrder(orderId);
        break;
      case OrderStatus.picked:
        promise = OrderUtilities.pickOrder(orderId);
        break;
      case OrderStatus.canceled:
        promise = OrderUtilities.cancelOrder(orderId, paymentMethod);
        break;
      default:
        break;
    }

    return promise
      .then(() => {
        Toastr.success("Order Updated Successfully");
        this.context.populateOrders();
        this.setState({ activeOrder: null });
      })
      .catch(error => {
        Toastr.error(error);
      });
  }

  render(): React.ReactNode {
    let activeOrders: OrderInterface[] = [];

    switch (this.state.activeTab) {
      case "new":
        activeOrders = this.context.newOrders ?? [];
        break;
      case "confirmed":
        activeOrders = this.context.confirmedOrder ?? [];
        break;
      case "ready":
        activeOrders = this.context.preparedOrders ?? [];
        break;
      default:
        activeOrders = [];
        break;
    }

    return (
      <div className="section order-listings__section">
        <div className="list-section">
          <h3>طلبات جارية التنفيذ</h3>
          <div className="refresh-orders">
            <button
              className="button--primary arabic-font"
              type="button"
              onClick={() => {
                this.context.populateOrders();
              }}
            >
              تحديث
            </button>
          </div>
          <ul className="orders-type-list">
            <li
              className={`list-item ${
                this.state.activeTab === "new" ? "list-item-active" : ""
              }`}
              onClick={() => {
                if (this.state.activeTab !== "new") {
                  this.setState({ activeTab: "new" });
                }
                Toastr.clear();
              }}
            >
              {this.context.newOrders?.length > 0 && (
                <div className="order-count">
                  {this.context.newOrders.length}
                </div>
              )}
              طلب جديد
            </li>
            <li
              className={`list-item ${
                this.state.activeTab === "confirmed" ? "list-item-active" : ""
              }`}
              onClick={() => {
                if (this.state.activeTab !== "confirmed") {
                  this.setState({ activeTab: "confirmed" });
                }
                Toastr.clear();
              }}
            >
              تم تأكيده
            </li>
            <li
              className={`list-item ${
                this.state.activeTab === "ready" ? "list-item-active" : ""
              }`}
              onClick={() => {
                if (this.state.activeTab !== "ready") {
                  this.setState({ activeTab: "ready" });
                }
                Toastr.clear();
              }}
            >
              جاهز للإستلام
            </li>
          </ul>
          <List>
            {activeOrders.map(order => {
              return (
                <ListItem
                  key={order.id}
                  onClick={() => this.onSelectOrder(order.id)}
                  active={this.state.activeOrder?.id === order.id}
                >
                  <div className="order-number">{order.serial}</div>
                  <div className="order-timing">
                    {order.received_at || order.created_at
                      ? _getTimeDistance(order.received_at ?? order.created_at)
                      : ""}
                  </div>
                  <div className="order-name">{order.user?.name}</div>
                  <div className="order-payment">
                    {order.payment_method === PaymentMethod.card
                      ? "مدفوع"
                      : "غير مدفوع"}
                  </div>
                  <div className="order-status">
                    {ArabicOrderStatus[order.state]}
                  </div>
                </ListItem>
              );
            })}
          </List>
        </div>
        <div className="order-details">
          {this.state.activeOrder && (
            <SubOrderDetails
              order={this.state.activeOrder}
              onUpdateOrderState={this.updateOrderState}
            />
          )}
        </div>
      </div>
    );
  }
}
OrdersListing.contextType = ORDERS_CONTEXT;
