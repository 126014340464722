/**
 * @author Ahmed Elshantaly
 * @date 2020-07-28
 * @description sub order details.
 * @filename sub-order-details.tsx
 */
import React from "react";
// import Toastr from "toastr";
import Time from "static/images/icons/time.svg";
import NotPaid from "static/images/icons/not-paid.svg";
import deleteIcon from "static/images/icons/delete.svg";
import {
  Order as OrderInterface,
  ArabicOrderStatus,
  OrderStatus,
  PaymentMethod
} from "interfaces/order";
import { ORDERS_CONTEXT } from "contexts/orders-context";
import { getTimeDistance as _getTimeDistance, exist } from "utilities/common";
import PrimaryButton from "components/common/primary-button";
import { ConfirmBox } from "components/common/confirm-box";
import { RefundEvent as _RefundEvent } from "utilities/gtag-events";
import Notification from "utilities/notification";

export class SubOrderDetails extends React.Component<
  {
    order: OrderInterface;
    onUpdateOrderState: (
      orderId: string,
      state: OrderStatus,
      paymentMethod?: PaymentMethod
    ) => Promise<void>;
  },
  {
    logoutModalActive: boolean;
    isCanceling?: boolean;
    isLoading?: boolean;
    copyBtnText: "Copy" | "Copied";
  }
> {
  declare context: React.ContextType<typeof ORDERS_CONTEXT>;

  constructor(props) {
    super(props);
    this.state = {
      logoutModalActive: false,
      copyBtnText: "Copy"
    };
    this.onConfirmCancel = this.onConfirmCancel.bind(this);
    this.getOrderDetailsText = this.getOrderDetailsText.bind(this);
  }

  onConfirmCancel() {
    if (!this.state.isCanceling) {
      this.setState({ isCanceling: true, isLoading: true });
      _RefundEvent({
        value: this.props.order.total_price,
        tax: this.props.order.tax,
        currency: "EGP",
        items: this.props.order.items.map(item => {
          return {
            id: `${item.id}`,
            price: item.price,
            quantity: item.quantity,
            name: item.product?.name
          };
        })
      });
      this.props
        .onUpdateOrderState(
          this.props.order.id,
          OrderStatus.canceled,
          this.props.order.payment_method
        )
        .finally(() => {
          this.setState({ isCanceling: false, isLoading: false });
        });
    }
  }

  getOrderDetailsText(): string {
    let orderDetails = `
    رقم العميل: ${this.props.order?.user?.phone}
    أسم العميل: ${this.props.order?.user?.name}
    رقم الطلب: ${this.props.order?.serial}


    سعر الطلب: ${this.props.order.total_price}

    طريقة الدفع: ${this.props.order?.payment_method}

    محتويات الطلب: 
    ${this.props.order.items?.map(item => {
      let customization = "";

      if (item.size) {
        customization = item.size.name;
      }

      if (item.extras) {
        for (const extra of item.extras) {
          customization = `${
            customization ? `${customization}, ` : ""
          }Extra ${extra.quantity ?? ""} ${extra?.name}`;
        }
      }

      if (item.variants) {
        for (const variant of item.variants) {
          customization = `${customization ? `${customization}, ` : ""}${
            variant.name
          }`;
        }
      }
      return `
      كمية: ${item.quantity} 
      المنتج: ${item.product.name} 
      التفاصيل: ${customization}
      السعر: ${item.price}
      `;
    })}
    `;

    if (this.props.order?.additional_info) {
      const orderAdditionalInfo = Object.keys(
        this.props.order?.additional_info
      ).reduce((prev, cur) => {
        if (
          cur === "location" &&
          exist(this.props.order?.additional_info[cur], [
            "latitude",
            "longitude"
          ])
        ) {
          return `${prev}
          المكان: https://www.google.com/maps/search/?api=1&query=${this.props.order?.additional_info[cur].latitude},${this.props.order?.additional_info[cur].longitude}
          `;
        }

        if (
          !exist(this.props.order?.additional_info[cur]) ||
          typeof this.props.order?.additional_info[cur] === "object"
        ) {
          return prev;
        }

        return `${prev}
        ${cur}: ${this.props.order.additional_info[cur]}
        `;
      }, "");
      orderDetails = `${orderDetails}
    ${orderAdditionalInfo}
    `;
    }

    return orderDetails;
  }

  render(): React.ReactNode {
    let OrderAdditionalInfo: React.ReactNode[];

    if (this.props.order.additional_info) {
      OrderAdditionalInfo = Object.keys(
        this.props.order?.additional_info
      ).reduce((prev, cur, index) => {
        if (
          cur === "location" &&
          exist(this.props.order?.additional_info[cur], [
            "latitude",
            "longitude"
          ])
        ) {
          return [
            ...prev,
            <div className="sub-order-details__order-comments" key={index}>
              <span>{cur}</span>
              <span>
                <a
                  href={`https://www.google.com/maps/search/?api=1&query=${this.props.order?.additional_info[cur].latitude},${this.props.order?.additional_info[cur].longitude}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  {`https://www.google.com/maps/search/?api=1&query=${this.props.order?.additional_info[cur].latitude},${this.props.order?.additional_info[cur].longitude}`}
                </a>
              </span>
            </div>
          ];
        }

        if (
          cur === "location" &&
          this.props.order?.additional_info[cur] === null
        ) {
          return [
            ...prev,
            <div className="sub-order-details__order-comments" key={index}>
              <span>{cur}</span>
              <span>لا يوجد</span>
            </div>
          ];
        }

        if (
          !exist(this.props.order?.additional_info[cur]) ||
          typeof this.props.order?.additional_info[cur] === "object"
        ) {
          return prev;
        }

        return [
          ...prev,
          <div className="sub-order-details__order-comments" key={index}>
            <span>{cur}</span>
            <span>{this.props.order.additional_info[cur]}</span>
          </div>
        ];
      }, []);
    }

    return (
      <>
        <div className="sub-order-details">
          <div className="sub-order-details__label">
            {ArabicOrderStatus[this.props.order.state]}
          </div>
          <div className="sub-order-details__wrapper">
            <div className="sub-order-details__header">
              <div>
                <div className="sub-order-details__header-title">
                  <span> طلب رقم</span>
                  {"  "}
                  <span className="sub-order-details__header-order-number">
                    {this.props.order.serial}
                  </span>
                </div>
                <div className="sub-order-details__header-time">
                  <div>
                    <img src={Time} alt="clock icon" />
                  </div>
                  <span>
                    {_getTimeDistance(
                      this.props.order.received_at ??
                        this.props.order.created_at
                    )}
                  </span>
                </div>
              </div>
              <div className="sub-order-details__header-action-button">
                {this.props.order.state === OrderStatus.received && (
                  <PrimaryButton
                    title="تأكيد الطلب"
                    disabled={this.state.isLoading}
                    onClick={() => {
                      Notification.getInstance()
                        .getAudioNotification()
                        .pause();
                      const promise = new Promise<void>(resolve => {
                        this.setState({ isLoading: true }, () => {
                          resolve();
                        });
                      });
                      promise
                        .then(() => {
                          return this.props.onUpdateOrderState(
                            this.props.order.id,
                            OrderStatus.confirmed
                          );
                        })
                        .finally(() => {
                          this.setState({ isLoading: false });
                        });
                    }}
                  />
                )}
                {this.props.order.state === OrderStatus.confirmed && (
                  <PrimaryButton
                    title="جاهز للإستلام"
                    disabled={this.state.isLoading}
                    onClick={() => {
                      const promise = new Promise<void>(resolve => {
                        this.setState({ isLoading: true }, () => {
                          resolve();
                        });
                      });
                      promise
                        .then(() => {
                          return this.props.onUpdateOrderState(
                            this.props.order.id,
                            OrderStatus.prepared
                          );
                        })
                        .finally(() => {
                          this.setState({ isLoading: false });
                        });
                    }}
                  />
                )}
                {this.props.order.state === OrderStatus.prepared && (
                  <PrimaryButton
                    title="إستلام"
                    disabled={this.state.isLoading}
                    onClick={() => {
                      const promise = new Promise<void>(resolve => {
                        this.setState({ isLoading: true }, () => {
                          resolve();
                        });
                      });
                      promise
                        .then(() => {
                          return this.props.onUpdateOrderState(
                            this.props.order.id,
                            OrderStatus.picked
                          );
                        })
                        .finally(() => {
                          this.setState({ isLoading: false });
                        });
                    }}
                  />
                )}
              </div>
            </div>
            <div className="sub-order-details__customer">
              <div className="sub-order-details__customer-name">
                <span>أسم العميل</span>
                <span>{this.props.order.user?.name}</span>
              </div>
              <div className="sub-order-details__customer-number">
                <span>رقم العميل</span>
                <span>{this.props.order.user?.phone}</span>
              </div>
            </div>
            <div className="sub-order-details__order">
              <h5>محتويات الطلب</h5>
              <div className="sub-order-details__order-header">
                <span>كمية</span>
                <span>المنتج</span>
                <span>التفاصيل</span>
                <span>السعر</span>
              </div>
              <div className="sub-order-details__order-body">
                <ul>
                  {this.props.order.items?.map(item => {
                    let customization = "";

                    if (item.size) {
                      customization = item.size.name;
                    }

                    if (item.extras) {
                      for (const extra of item.extras) {
                        customization = `${
                          customization ? `${customization}, ` : ""
                        }Extra ${extra.quantity ?? ""} ${extra?.name}`;
                      }
                    }

                    if (item.variants) {
                      for (const variant of item.variants) {
                        customization = `${
                          customization ? `${customization}, ` : ""
                        }${variant.name}`;
                      }
                    }
                    return (
                      <li key={item.id}>
                        <div className="sub-order-details__order-item">
                          <span>{item.quantity}</span>
                          <span>{item.product.name}</span>
                          <span>{customization}</span>
                          <span>
                            {item?.is_free_gift
                              ? "Free gift"
                              : Number(item.price)?.toFixed(2)}
                          </span>
                        </div>
                      </li>
                    );
                  })}
                </ul>
              </div>
              <div className="sub-order-details__order-service">
                <span>الخصم</span>
                <span>
                  {`- ${
                    this.props.order.discount
                      ? Number(this.props.order.discount)?.toFixed(2)
                      : 0
                  } EGP`}
                </span>
              </div>
              {Number(this.props.order?.subscription_discount) > 0 && (
                <div
                  className="sub-order-details__order-service"
                  style={{ backgroundColor: "#FBD603" }}
                >
                  <span>خصم الاشتراك</span>
                  <span>
                    {`- ${
                      this.props.order.subscription_discount
                        ? Number(
                            this.props.order.subscription_discount
                          )?.toFixed(2)
                        : 0
                    } EGP`}
                  </span>
                </div>
              )}
              {exist(this.props.order, [
                "promocode_discount_amount",
                "promocode"
              ]) && (
                <div className="sub-order-details__order-service">
                  <span>الكود</span>
                  <span>
                    {this.props.order.promocode}
                    {`(${this.props.order.promocode_percentage}%)`}
                  </span>
                  <span>{`- ${this.props.order.promocode_discount_amount} EGP`}</span>
                </div>
              )}
              <div className="sub-order-details__order-service">
                <span>الضريبة</span>
                <span>
                  {`${
                    this.props.order.tax
                      ? Number(this.props.order.tax).toFixed(2)
                      : 0
                  } EGP`}
                </span>
              </div>
              {/* <div className="sub-order-details__order-service">
                <span>Table Number</span>
                <span>{`${this.props.order.table_number ?? ""}`}</span>
              </div> */}
              {exist(this.props.order.gift) && this.props.order.gift > -1 && (
                <div className="sub-order-details__order-service order-gift">
                  <span className="gift-title">يوجد هدية من المستوى</span>
                  <span>{Number(this.props.order.gift) + 1}</span>
                </div>
              )}
              {!OrderAdditionalInfo && (
                <div className="sub-order-details__order-service order-gift">
                  <span>نوع الطلب</span>
                  <span>تسليم في الفرع</span>
                </div>
              )}
              <div className="sub-order-details__order-comments">
                <span>تعليقات العميل</span>
                <span>{this.props.order.comment}</span>
              </div>
              {OrderAdditionalInfo}
              <div className="sub-order-details__order-comments">
                <PrimaryButton
                  title={this.state.copyBtnText}
                  onClick={() => {
                    navigator.clipboard.writeText(this.getOrderDetailsText());
                    // Change the text for 1 second
                    this.setState({ copyBtnText: "Copied" }, () => {
                      setTimeout(() => {
                        this.setState({ copyBtnText: "Copy" });
                      }, 1000);
                    });
                  }}
                />
              </div>
            </div>
            <div className="sub-order-details__comment">
              <span>
                للطلبات التي تزيد عن 200 جنيه يرجى الاتصال بالعميل لتأكيد الطلب
              </span>
              <button
                type="button"
                className="button--delete arabic-font"
                onClick={() => {
                  if (!this.state.isLoading) {
                    this.setState({ logoutModalActive: true });
                  }
                }}
              >
                <img src={deleteIcon} alt="log off icon" />
                <span className="nav__link--name">الغاء الطلب</span>
              </button>
            </div>
          </div>
          <div className="sub-order-details__footer">
            <div className="sub-order-details__footer-info">
              <div className="sub-order-details__footer-price">
                <span>إجمالي السعر</span>
                <span>
                  {`${
                    this.props.order.total_price
                      ? Number(this.props.order.total_price)?.toFixed(2)
                      : 0
                  } EGP`}
                </span>
              </div>
              <div className="sub-order-details__footer-status">
                <span>حالة السداد</span>
                <div>
                  {this.props.order.payment_method === PaymentMethod.cash && (
                    <img src={NotPaid} alt="not paid icon" />
                  )}
                </div>
                <span>
                  {this.props.order.payment_method === PaymentMethod.card
                    ? "مدفوع"
                    : "غير مدفوع"}
                </span>
              </div>
            </div>
            {/* <button type="button"> الدفع</button> */}
          </div>
        </div>
        {this.state.logoutModalActive && (
          <ConfirmBox
            onConfirmSuccess={this.onConfirmCancel}
            onConfirmFail={() => this.setState({ logoutModalActive: false })}
            confirmationTitle={`تأكيد إلغاء الطلب رقم ${this.props.order.serial}`}
            confirmationText="هل أنت متأكد من إلغاء هذا الطلب؟"
            cancelProcess="لا"
            confirmProcess="نعم"
            disabled={this.state.isLoading}
            // switchButtons
          />
        )}
      </>
    );
  }
}
SubOrderDetails.contextType = ORDERS_CONTEXT;
