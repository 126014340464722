/**
 * @author Raghda Wessam
 * @date 2020-07-13
 * @description Branch Products layout.
 * @filename branch-products.tsx
 */
import React from "react";
import Toastr from "toastr";
import { MENU_CONTEXT } from "contexts/menu-context";
import { Product as ProductUtilities } from "utilities/product";
import ToggleOn from "static/images/icons/toggle-on.svg";
import ToggleOff from "static/images/icons/toggle-off.svg";
import { isEmpty } from "utilities/common";

export interface BranchProductsProps {
  header: string;
  listItemsType: "product" | "customization";
  products: Record<
    string,
    {
      id: string;
      name: string;
      available: boolean;
      price?: number;
    }[]
  >;
  type?: string;
}

interface BranchProductsState {
  updatedItems: Record<
    string,
    {
      added: string[];
      removed: string[];
    }
  >;
}

export class BranchProducts extends React.Component<
  BranchProductsProps,
  BranchProductsState
> {
  declare context: React.ContextType<typeof MENU_CONTEXT>;

  constructor(props: BranchProductsProps) {
    super(props);
    const updatedItems: Record<
      string,
      {
        added: string[];
        removed: string[];
      }
    > = {};

    if (props.listItemsType === "product") {
      updatedItems.product = {
        added: [],
        removed: []
      };
    }

    if (props.listItemsType === "customization") {
      updatedItems.variants = {
        added: [],
        removed: []
      };

      updatedItems.extras = {
        added: [],
        removed: []
      };
    }

    this.state = { updatedItems };
    this.getProductAvailability = this.getProductAvailability.bind(this);
    this.onChangeProductAvailability = this.onChangeProductAvailability.bind(
      this
    );
    this.saveProductsUpdates = this.saveProductsUpdates.bind(this);
    this.updateProductAvailablity = this.updateProductAvailablity.bind(this);
  }

  onChangeProductAvailability(
    productId: string,
    available: boolean,
    productType: "product" | "variants" | "extras"
  ) {
    // TODO Write this block in a better way!!!
    if (
      available &&
      this.state.updatedItems[productType].removed.indexOf(productId) > -1
    ) {
      const { updatedItems } = this.state;
      updatedItems[productType].removed.splice(
        updatedItems[productType].removed.indexOf(productId),
        1
      );
      this.setState({ updatedItems });
    } else if (available) {
      const { updatedItems } = this.state;
      updatedItems[productType].added.push(productId);
      this.setState({ updatedItems });
    } else if (
      !available &&
      this.state.updatedItems[productType].added.indexOf(productId) > -1
    ) {
      const { updatedItems } = this.state;
      updatedItems[productType].added.splice(
        updatedItems[productType].added.indexOf(productId),
        1
      );
      this.setState({ updatedItems });
    } else if (!available) {
      const { updatedItems } = this.state;
      updatedItems[productType].removed.push(productId);
      this.setState({ updatedItems });
    }
  }

  getProductAvailability(
    productId: string,
    initialAvailability: boolean,
    productType: "product" | "variants" | "extras"
  ): boolean {
    let available =
      initialAvailability &&
      this.state.updatedItems[productType].removed.indexOf(productId) < 0;
    available =
      available ||
      this.state.updatedItems[productType].added.indexOf(productId) >= 0;

    return available;
  }

  saveProductsUpdates() {
    let promise: Promise<unknown>;
    if (this.props.listItemsType === "product") {
      promise = ProductUtilities.updateProducts(
        this.state.updatedItems.product.added,
        this.state.updatedItems.product.removed
      );
    }

    if (this.props.listItemsType === "customization") {
      promise = ProductUtilities.updateProductsCustomizations(
        this.state.updatedItems.extras,
        this.state.updatedItems.variants
      );
    }

    promise = promise.then(() => {
      Toastr.success("Menu Updated successfully");
      this.updateProductAvailablity();
    });

    promise.catch(e => {
      Toastr.error(e);
    });
  }

  updateProductAvailablity() {
    const updatedMenu = {
      preppedProducts: this.context.preppedProducts,
      readyProducts: this.context.readyProducts,
      extras: this.context.extras,
      variants: this.context.variants
    };
    if (this.props.listItemsType === "product") {
      const updatedItems = this.context[this.props.type].map(product => {
        let newProduct = { ...product };
        this.state.updatedItems.product.added.map(id => {
          if (product.id === id) {
            newProduct = {
              ...product,
              available: true
            };
          }
          return id;
        });
        this.state.updatedItems.product.removed.map(id => {
          if (product.id === id) {
            newProduct = {
              ...product,
              available: false
            };
          }
          return id;
        });
        return newProduct;
      });
      updatedMenu[this.props.type] = updatedItems;
    }
    if (this.props.listItemsType === "customization") {
      const updatedExtras = this.context.extras.map(product => {
        let newProduct = { ...product };
        this.state.updatedItems.extras.added.map(id => {
          if (product.id === id) {
            newProduct = {
              ...product,
              available: true
            };
          }
          return id;
        });
        this.state.updatedItems.extras.removed.map(id => {
          if (product.id === id) {
            newProduct = {
              ...product,
              available: false
            };
          }
          return id;
        });
        return newProduct;
      });
      updatedMenu.extras = updatedExtras;

      const updatedVariants = this.context.variants.map(product => {
        let newProduct = { ...product };
        this.state.updatedItems.variants.added.map(id => {
          if (product.id === id) {
            newProduct = {
              ...product,
              available: true
            };
          }
          return id;
        });
        this.state.updatedItems.variants.removed.map(id => {
          if (product.id === id) {
            newProduct = {
              ...product,
              available: false
            };
          }
          return id;
        });
        return newProduct;
      });
      updatedMenu.variants = updatedVariants;
    }
    this.context.updateMenu(this.context.branchId, updatedMenu);
  }

  render(): React.ReactNode {
    return (
      <div className="products__wrapper">
        <div className="products__header-wrapper">
          <h2 className="products__header arabic-font">{this.props.header}</h2>
          <button
            className="button--primary"
            type="button"
            onClick={() => {
              this.saveProductsUpdates();
            }}
          >
            حفظ
          </button>
        </div>
        <div className="categories-container">
          {!isEmpty(Object.keys(this.props.products)) &&
            Object.keys(this.props.products).map(category => {
              return (
                <div className="category__wrapper" key={category}>
                  <h3 className="category__name">{category}</h3>
                  {!isEmpty(this.props.products[category]) && (
                    <ul className="category__products-list general__list">
                      {this.props.products[category].map(product => {
                        return (
                          <li
                            className="category__products-list-item secondary-font"
                            key={product.id}
                          >
                            {product.name}
                            <span>{`${product.price} EGP`}</span>
                            {this.getProductAvailability(
                              product.id,
                              product.available,
                              this.props.listItemsType === "product"
                                ? "product"
                                : category === "extras"
                                ? "extras"
                                : "variants"
                            ) ? (
                              // TODO this needs to be changed to a checkbox button
                              <img
                                src={ToggleOn}
                                alt="available"
                                onClick={() => {
                                  this.onChangeProductAvailability(
                                    product.id,
                                    false,
                                    this.props.listItemsType === "product"
                                      ? "product"
                                      : category === "extras"
                                      ? "extras"
                                      : "variants"
                                  );
                                }}
                              />
                            ) : (
                              <img
                                src={ToggleOff}
                                alt="not available"
                                onClick={() => {
                                  this.onChangeProductAvailability(
                                    product.id,
                                    true,
                                    this.props.listItemsType === "product"
                                      ? "product"
                                      : category === "extras"
                                      ? "extras"
                                      : "variants"
                                  );
                                }}
                              />
                            )}
                          </li>
                        );
                      })}
                    </ul>
                  )}
                </div>
              );
            })}
        </div>
      </div>
    );
  }
}
BranchProducts.contextType = MENU_CONTEXT;
